<template>
  <v-container fluid>
    <v-row no-gutters>
      <v-col>
        <v-card class="pa-2 ma-2" outlined tile>
          <h1 style="text-align: center">{{ options.title }}</h1>
          <v-tabs class="elevation-2" dark :grow="true" show-arrows>
            <v-tabs-slider :color="colorSlider"></v-tabs-slider>
            <v-tab
              v-for="tab in options.tabs"
              :key="tab.name"
              :href="`#tab-${tab.name}`"
              @click="selectTab(tab)"
              :style="{ color: colorText }"
            >
              {{ tab.name }}
            </v-tab>
            <v-tab-item
              v-for="tab in options.tabs"
              :key="tab.name"
              :value="'tab-' + tab.name"
            >
            </v-tab-item>
          </v-tabs>
          <item-table :headers="options.headers" :items="options.items" />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { Vue } from "vue-property-decorator";
import { get } from "vuex-pathify";
import { mapActions } from "vuex";

export default Vue.extend({
  name: "AbnormalValue-Oil",
  components: {
    ItemTable: () => import("@/components/report/ReportTable.vue"),
  },
  computed: {
    options: get("report/optionsAnomalies"),
  },

  data() {
    return {
      colorText: "#BBDEFB",
      colorSlider: "#BBDEFB",
      items: [],
    };
  },
  methods: {
    ...mapActions({
      fetchReport: "report/fetchAnomaliesData",
      selectData: "report/selectAnomaliesData",
    }),
    selectTab(tab) {
      if (this.currentTab !== tab) {
        this.currentTab = tab;
        this.selectData(this.currentTab.link.toLowerCase());
      }
    },
  },
  created() {
    this.fetchReport();
  },
  mounted() {
    this.selectData("max");
  },
});
</script>